export function breakpoint(width) {
  if (width === null) return null;
  if (width < 576) return 'xsmall';
  if (width < 768) return 'small';
  if (width < 992) return 'medium';
  if (width < 1200) return 'large';
  if (width < 1400) return 'xlarge';
  return 'xxlarge';
}

export function pageUrl() {
  const path = document.body.dataset.urlRewrite;
  if (path === '') {
    return document.location.href;
  }
  const url = new URL(document.location.href);
  return `${url.protocol}//${url.hostname}${path}${window.location.search}`;
}
